@import 'assets/styles/main.scss';
@import 'assets/styles/base/variables.scss';

/* -------------------
       Headers
-------------------- */
h1,
.ui3_h1 {
  font: $h1;
  color: $h1Color;
  margin: $headersMargin;
}

h2,
.ui3_h2 {
  font: $h2;
  color: $h2Color;
  margin: $headersMargin;
}

h3,
.ui3_h3 {
  font: $h3;
  color: $h3Color;
  margin: $headersMargin;
}

h4,
.ui3_h4 {
  font: $h4;
  color: $h4Color;
  margin: $headersMargin;
}

/* -------------------
       Links
-------------------- */
a {
  cursor: pointer;
  font: $hyperlink;
  color: $hyperlinkColor;
  margin: $textsMargin;
}

a.small {
  cursor: pointer;
  font: $smallerHyperlink;
  color: $smallerHyperlinkColor;
  margin: $textsMargin;
}

a:link {
  text-decoration: none;
}

/* -------------------
       Text
-------------------- */
p {
  font: $bodyCopy1;
  color: $bodyCopy1Color;
  margin: $textsMargin;
}

p.small {
  font: $bodyCopy2;
  color: $bodyCopy2Color;
  margin: $textsMargin;
}

p.subtext {
  font: $subtext;
  font-style: normal;
  color: $subtextColor;
  margin: $textsMargin;
}

p.error {
  color: $ui3_system-ruby;
}

p.success {
  color: $bodyCopy5Color;
}

p.light {
  color: $bodyCopy3Color;
}

b {
  font: $bodyCopy4;
  color: $bodyCopy4Color;
  margin: $textsMargin;
}

b.small {
  font: $bodyCopy5;
  color: $bodyCopy4Color;
  margin: $textsMargin;
}

b.completed {
  font: $bodyCopy4;
  color: $bodyCopy5Color;
  margin: $textsMargin;
}

b.large {
  font: $bodyCopy6;
  color: $bodyCopy6Color;
  margin: $textsMargin;
}

b.light {
  color: $ui3_primary-violet;
}

b.error {
  color: $ui3_system-ruby;
}

i {
  display: block;
  font: $subtext;
  color: $subtextColor;
  margin: $textsMargin;
}

i.error {
  display: block;
  font: $subtext;
  color: $ui3_system-ruby;
  margin: $textsMargin;
}
