@use '../base/variables' as *;

ui-container {
  position: relative;
  flex: 1;
}

ui-container.full-height {
  top: -3.5rem;
  background: $white;
  z-index: 2;
  width: 100%;
  height: calc(100% + 3.5rem);
}

/* -------------------
        Container
-------------------- */

div.ui-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 1rem;
}

div.ui-container div.container-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 30rem;
  text-align: center;
}

div.ui-container div.container-content.with-header {
  min-height: calc(100% - 3.5rem);
  margin-top: 3.5rem;
}

/* -------------------
   Container helper
       classes
-------------------- */
div.ui-container.chat {
  margin-bottom: 3.3rem;
  overflow-x: hidden;
}

div.ui-container.transparent {
  background: transparent !important;
}

div.ui-container.compact {
  padding: 1rem 0 $containerVerticalPadding 0 !important;
}

div.ui-container.no-bottom-padding {
  padding-bottom: 0 !important;
}

div.ui-container.no-top-padding {
  padding-top: 0 !important;
}

div.ui-container.borderless {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
}

div.ui-container.left div.container-content {
  text-align: left;
}

div.ui-container.right div.container-content {
  text-align: right;
}

div.project-details-wrapper div[class*='ui-container']:not(.h-scroll-container) {
  min-height: calc(100vh - #{$applicationHeaderHeight} - #{$projectDetailsOverviewHeight});

  div[class*='segment'] {
    min-height: 100%;
  }
}

div.ui-container.with-navigation {
  padding-bottom: 5rem !important;
}

div.ui-container.gold-background {
  background-color: $secondary6;
}

div.ui-container div.row.left.typing.indicator img {
  width: 4rem;
  height: auto;
}

div.ui-container.stretch-container div.container-content {
  height: 100%;
}
