@use '../base/variables' as *;

wakanda-merchant-navigation,
wakanda-customer-navigation {
  z-index: 1401;
}

footer.navigation-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: $ui3_primary-white;
  padding: 0 1rem;
  box-shadow: $ui3_shadow-item;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    max-width: 26.938rem;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
}

footer.navigation-container div.navigation-items {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-items: center;

  /* -------------------
            Items
    -------------------- */
  wakanda-merchant-navigation-item {
    flex: 1;
  }

  wakanda-merchant-navigation-item-icon {
    z-index: 1;
  }

  div.row {
    display: flex;
    height: 100%;
  }

  div.item.disabled {
    opacity: 0.25;
  }

  div.item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    box-sizing: border-box;

    &.active:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 0.25rem;
      border-radius: 0.25rem 0.25rem 0 0;
      background-color: $ui3_primary-gone-green;
    }

    img.avatar {
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
      background: $black;
    }

    p.content {
      font-size: $ui3_h6;
      color: $ui3_primary-purple;
      line-height: $ui3_h6-line;
    }

    ui-icon {
      padding: 10px 0;
    }
  }
}
