@use '../base/variables' as *;

/* -------------------
       Helpers
-------------------- */
.h-scroll-content {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;

  ui-button {
    width: auto;
  }

  @media #{$mobile} {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.h-scroll-content:not(.compact) > *:first-child {
  margin-left: $pageContentPaddingOnSide;
}

.h-scroll-content:not(.compact) > .end {
  width: $pageContentPaddingOnSide;
  flex: 0 0 $pageContentPaddingOnSide;
}

.h-virtual-scroll-content {
  width: 100%;

  @media #{$mobile} {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.h-virtual-scroll-content:not(.compact) > *:first-child {
  margin-left: $pageContentPaddingOnSide;
}

.h-virtual-scroll-content:not(.compact) .end {
  width: $pageContentPaddingOnSide;
  flex: 0 0 $pageContentPaddingOnSide;
}

.h-virtual-scroll-content .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: row;
}

.padded-content {
  padding: 0 $pageContentPaddingOnSide;
}

.bottom-content {
  display: flex;
  flex-direction: column;
  margin: auto 0 0;
}

.text-align-left {
  text-align: left;
  align-items: flex-start;
}

.text-align-right {
  text-align: right !important;
  align-items: flex-end;
}

.text-align-center {
  text-align: center;
  align-items: center;
}

.column,
.row {
  > *.flex-1 {
    flex: 1;
  }

  > *.flex-2 {
    flex: 2;
  }

  > *.flex-3 {
    flex: 3;
  }

  > *.flex-4 {
    flex: 4;
  }

  > *.flex-5 {
    flex: 5;
  }

  > *.basis-5 {
    flex-basis: 5%;
  }

  > *.basis-10 {
    flex-basis: 10%;
  }

  > *.basis-15 {
    flex-basis: 15%;
  }

  > *.basis-20 {
    flex-basis: 20%;
  }

  > *.basis-25 {
    flex-basis: 25%;
  }

  > *.basis-30 {
    flex-basis: 30%;
  }

  > *.basis-35 {
    flex-basis: 35%;
  }

  > *.basis-40 {
    flex-basis: 40%;
  }

  > *.basis-45 {
    flex-basis: 45%;
  }

  > *.basis-50 {
    flex-basis: 50%;
  }

  > *.basis-55 {
    flex-basis: 55%;
  }

  > *.basis-60 {
    flex-basis: 65%;
  }

  > *.basis-70 {
    flex-basis: 70%;
  }

  > *.basis-75 {
    flex-basis: 75%;
  }

  > *.basis-80 {
    flex-basis: 80%;
  }

  > *.basis-85 {
    flex-basis: 85%;
  }

  > *.basis-90 {
    flex-basis: 90%;
  }

  > *.basis-95 {
    flex-basis: 95%;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.gap {
    gap: 1rem;
  }

  &.gap-big {
    gap: 2rem;
  }

  &.center {
    align-items: center;
  }

  &.between {
    justify-content: space-between;
  }

  &.evenly {
    justify-content: space-evenly;
  }

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }

  &.top {
    justify-content: flex-start;
  }

  &.bottom {
    justify-content: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.gap {
    gap: 1rem;
  }

  &.gap-big {
    gap: 2rem;
  }

  &.center {
    justify-content: center;
  }

  &.between {
    justify-content: space-between;
  }

  &.evenly {
    justify-content: space-evenly;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }
}

.link-right {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.link-left {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.link-center {
  margin: 0 auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.word-break {
  word-break: break-word;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
