@use '../base/variables' as *;

$selectBorder: 1px solid $secondary2;
$selectFocusedBorder: 1px solid $secondary4;

$selectFont: $bodyCopy1;
$selectFontColor: $bodyCopy1Color;
$selectFontErrorColor: $text3;
$selectCaretColor: $text4;

ui-make-search-select {
  display: flex;
  flex-direction: column;
  flex: 1;
}

div.ui.select {
  width: 100%;
  height: 2.188rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: $selectBorder;
  background: $white;
  border-radius: 1.063rem;
  margin: 0;
  padding: 0 0.8rem;
  position: relative;
  box-sizing: border-box;
}

/* -------------------
    On Select Focus
-------------------- */
div.ui.select.focused {
  border: $selectFocusedBorder;
  transition: border 250ms ease-out;
}

/* -------------------
       Input
-------------------- */
div.ui.select input {
  flex: 1 0 auto;
  font: $selectFont;
  color: $selectFontColor;
  border: none !important;
  outline: none !important;
  margin: 0 0.8rem 0 0;
  caret-color: $selectCaretColor;
  text-align: left;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* -------------------
       Icons
-------------------- */
div.ui.select ui-icon[icon='cross'] {
  width: 0.9em;
  animation: $fadeInAnimationKey 250ms ease-out;
}

div.ui.select ui-icon[icon='search'] {
  width: 1rem;
  animation: $fadeInAnimationKey 250ms ease-out;
}

/* -------------------
        Menu
-------------------- */
div.select-menu {
  width: calc(100% - 2.5em);
  position: absolute;
  border-radius: 0;
  box-shadow: none;
  background: $white;
  z-index: 2;
}

/* -------------------
      Menu Item
-------------------- */
div.select-menu div.select-item {
  width: calc(100% - 2rem);
  height: 2.313rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  text-align: left;
  padding: 0 1rem;
  border-bottom: 1px dotted $secondary2;
  z-index: 2;
}

div.select-menu div.select-item:last-child {
  border: none;
}

div.select-menu div.select-item p {
  flex: 1 0 auto;
  font: $bodyCopy1;
  color: $text1;
}

div.select-menu div.select-item svg[icon='cross'] {
  margin: 0 0 0 auto;
}

/* -------------------
   Highlight results
-------------------- */
span.searched-text {
  font: $bodyCopy4;
  font-size: 0.875rem;
  color: $text1;
}

/* -------------------
      Map Select
-------------------- */
div.ui.select-wrapper.map {
  position: absolute;
  width: calc(100% - 4em);
  top: 1em;
  left: 1em;
  z-index: 1;
  padding: 1em;
  margin-bottom: 1rem;
  background: $white;
  border-radius: 1.1em;
  box-shadow: $ui3_shadow-item;
}

div.ui.select-wrapper.map div.ui.select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.188rem;
  border: none;
  border-bottom: 1px solid $secondary2;
  background: transparent;
  border-radius: 0;
  padding: 0 0.8rem;
  margin: 0;
  box-sizing: border-box;
}

div.ui.select-wrapper.map div.ui.select.focused {
  border: none;
  border-bottom: 1px solid $secondary2;
  transition: none;
}

div.ui.select-wrapper.map div.select-menu.map {
  width: 100%;
  position: initial !important;
}

div.ui.select-wrapper.map div.select-menu.map div.select-item.map {
  width: 100%;
  min-height: 4rem;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  text-align: left;
  padding: 0.35rem 0;
  border-bottom: 1px dotted $secondary2;
  box-sizing: border-box;
  z-index: 2;

  span.item-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
}

div.ui.select-wrapper.map div.select-menu.map div.select-item.map:last-of-type {
  border-bottom: none;
}

div.ui.select-wrapper.map div.select-menu.map div.select-item.map p.small.light {
  font: $bodyCopy2;
  color: $secondary2;
}

div.ui.select-wrapper.map div.select-menu.map div.select-item.map p.small.light,
div.ui.select-wrapper.map div.select-menu.map div.select-item.map p,
div.ui.select-wrapper.map div.select-menu.map div.select-item.map p.small {
  height: auto;
}

div.ui.select-wrapper.map div.select-menu.map div.select-item.map ui-icon {
  padding: 0 0.6rem;
}

div.ui.select-wrapper.map div.select-menu.map div.footer {
  text-align: left;
  margin: -1px -1em 0;
  padding: 0.5em 1.5em 0;
  border-top: 1px solid $secondary2;
}

div.ui.select-wrapper.map-search {
  top: 1em;
  z-index: 1;
  padding: 1em;
}

// Ui3
div.ui.select {
  background: $ui3_secondary-concrete;
  border-radius: $ui3_rounded-lg;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0;
  height: 2.5rem;

  input {
    padding-right: 0.5rem;
    color: $ui3_primary-purple;
    font-size: $ui3_h4;
    background: transparent;
    border: 0;
    outline: 0;

    &::placeholder {
      color: $ui3_primary-purple;
    }
  }

  &.focused {
    border: 0;
  }
}
