@use '../base/variables' as *;

.ui3_header-outer-container {
  &:not(.limited) {
    background-color: $ui3_primary-white;
    box-shadow: $ui3_shadow-layout;
    position: relative;
    z-index: 2;
  }
}

header.header-container {
  min-height: $applicationHeaderHeight;
  padding: 0 1rem 0 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    max-width: 26.938rem;
    box-sizing: border-box;
  }
}

header.header-container.limited {
  height: $applicationHeaderHeight;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 1rem 0 1.25rem;
  box-sizing: border-box;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    max-width: 28rem;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

/* -------------------
       ICON
-------------------- */

header.header-container span.header-icon {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 0.75rem;
}

header.header-container span.header-icon-my-cart {
  display: flex;
  height: 100%;
  align-items: center;
}

header.header-container span.header-icon > i.icon.merchant-back-arrow-inverted,
header.header-container span.header-icon > i.icon.back-arrow {
  cursor: pointer;
  width: 0.5rem;
}

header.header-container span.header-icon-mycart > ui-icon[icon='my-cart'] {
  cursor: pointer;
  width: 0.5rem;
}

ui-icon.avo-logo-by-nedbank {
  position: absolute;
  left: calc(50% - 2.2rem);
  right: calc(50% - 2.2rem);
}

/* -------------------
       INNER
-------------------- */
header.header-container div.header-inner {
  flex: 1;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

header.header-container div.header-inner div.chained-header-title {
  flex: 1;

  h3 {
    cursor: pointer;
  }

  h3.active {
    cursor: initial;
    color: $secondary5;
  }

  ui-icon.next {
    padding: 0 0.5rem;
  }
}

/* -------------------
        Title
-------------------- */
header.header-container div.header-inner span.header-title {
  width: 100%;
  overflow-x: hidden;
  flex: 1;
  min-width: 0;
  padding: 0 0 0 0.5rem;

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-weight: 500;
  }
}

header.header-container .small-task-spinner {
  margin-right: 0.5rem;
}

/* -------------------
     tab-wrapper
-------------------- */
header.header-container div.header-inner span.header-tab-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  z-index: 2;
}
