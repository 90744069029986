@use '../base/variables' as *;

/* -------------------
      Basic card
-------------------- */
div.ui-card.basic {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(
    100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide}
  );
  border-radius: $ui3_rounded-xl;
  padding: $pageContentPaddingOnSide;
  margin: 0 $pageContentPaddingOnSide;
  opacity: 1;
  background: $white;
  border: 1px solid $ui3_secondary-concrete;
  transition: all 0.3s ease-out;
}

div.ui-card.basic.clickable {
  cursor: initial;
}

div.ui-card.basic.inactive {
  cursor: default;
  opacity: 0.4;
  background: $white;
  border: 1px solid $ui3_secondary-concrete;
  transition: all 0.3s ease-out;
}

div.ui-card.basic div.basic-content {
  width: 100%;
}

/* -------------------
      Info card
-------------------- */
div.ui-card.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 2em;
  background: $white;
  border-radius: $ui3_rounded-xl;
  border: 1px solid $secondary2;
  transition: all 0.3s ease-out;
}

div.ui-card.info div.title-content {
  width: calc(100% - 2em);
  height: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1em;
  background: $secondary1;
  border-bottom: 1px dotted $secondary2;
  border-top-left-radius: $ui3_rounded-xl;
  border-top-right-radius: $ui3_rounded-xl;
}

div.ui-card.info div.basic-content {
  width: 100%;
}

/* -------------------
   Card description
-------------------- */
div.ui-card div.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: $white;
  border-bottom-right-radius: $ui3_rounded-xl;
  border-bottom-left-radius: $ui3_rounded-xl;
  align-items: flex-start;

  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

div.ui-card div.description div.with-favourite {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    flex: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ui-icon {
    margin: 0 auto 0 0;
  }
}

div.ui-card div.description div.price {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  ui-available-balance:first-of-type {
    margin-right: 0.3rem;
  }
}

/* ----------------------
        FOOTER
---------------------- */
div.ui-card div.footer {
  width: 100%;
  min-height: $pageContentPaddingOnSide;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 $pageContentPaddingOnSide;
  margin-bottom: -$pageContentPaddingOnSide;
  background: $secondary2;
  border-bottom-left-radius: $ui3_rounded-xl;
  border-bottom-right-radius: $ui3_rounded-xl;

  i {
    font-size: 0.75rem;
    color: $text1;
  }
}

/* -------------------
DISCOUNT FLAG
------------------- */
$discountArrowWidth: calc(#{$discountFlagWidth} / 2);
$discountArrowHeight: calc(#{$discountFlagWidth} / 4);

div.ui-card div.content {
  position: relative;
}

div.ui-card div.content .discount-flag {
  position: absolute;
  top: 0;
  left: 0.75rem;
  display: block;
  width: $discountFlagWidth;

  .discount-flag-content {
    display: block;
    background-color: $secondary4;
    padding: 0.2rem;
    text-align: center;

    p,
    b {
      font-size: 0.6rem;
      color: $white;
    }
  }

  .discount-corners {
    position: relative;

    .discount-corner {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      top: -1px;

      &.right {
        border-width: 0 $discountArrowWidth $discountArrowHeight 0;
        border-color: transparent $secondary4 transparent transparent;
        right: 0;
      }

      &.left {
        border-width: $discountArrowHeight $discountArrowWidth 0 0;
        border-color: $secondary4 transparent transparent transparent;
        left: 0;
      }
    }
  }
}

/* -------------------
PRODUCT_HORIZONTAL_BIG
         &
   BLOG_HORIZONTAL
-------------------- */
div.ui-card.blog-horizontal,
div.ui-card.product-horizontal-big {
  width: 15.635rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.625rem 0 0;
}

div.ui-card.blog-horizontal div.content img,
div.ui-card.product-horizontal-big div.content img {
  width: 15.635rem;
  height: 9.938rem;
  object-fit: cover;
  background: $secondary2;
  border-radius: $ui3_rounded-xl;
  overflow: hidden;
}

/* ----------------------
PRODUCT_HORIZONTAL_SMALL
---------------------- */
div.ui-card.product-horizontal-small {
  width: calc(10rem - 2px); //these are the 2 pixels that make up the borders, otherwise things scroll
  height: 12.5rem;
  border: 1px solid $secondary2;
  border-radius: $ui3_rounded-xl;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.625rem 0 0;
}

div.ui-card.product-horizontal-small div.content img {
  width: calc(10rem - 2px);
  height: 7.688rem;
  object-fit: cover;
  background: $secondary2;
  border-top-left-radius: $ui3_rounded-xl;
  border-top-right-radius: $ui3_rounded-xl;
  overflow: hidden;
}

div.ui-card.product-horizontal-small div.description {
  width: calc(100% - 1.25rem);
  height: calc(100% - 1.013rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
  padding: 0.2rem 0.625rem 0.813rem 0.625rem;
}

/* ----------------------
    BRAND_HORIZONTAL
---------------------- */
div.ui-card.brand-horizontal {
  width: 6.25rem;
  height: 4.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.625rem 0 0;
}

div.ui-card.brand-horizontal div.content img {
  width: 6.25rem;
  height: 3.125rem;
  object-fit: cover;
  background: $secondary2;
  border-radius: $ui3_rounded-xl;
  overflow: hidden;
}

/* ----------------------
    FILTER_VERTICAL
---------------------- */
div.ui-card.filter-vertical {
  width: calc(100vw - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide});
  height: 6.25rem;
  margin: 0 0 0.313rem 0;
}

div.ui-card.filter-vertical div.content img {
  width: calc(100vw - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide});
  height: 6.25rem;
  object-fit: cover;
  background: $secondary2;
  border-radius: $ui3_rounded-xl;
  overflow: hidden;
}

div.ui-card.filter-vertical div.content h2 {
  color: $white;
  margin: -4rem auto;
}

div.ui-card.compact {
  margin: 0;
}

div.ui-card.basic.compact {
  width: 100%;
}
