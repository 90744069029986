@use '../base/variables' as *;

div.ui-letter-avatar {
  cursor: pointer;
  width: 1.3em;
  height: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em;
  border-radius: 50%;
  background: $black;

  p {
    color: $white;
    font-size: 0.9em;
  }
}

div.ui-letter-avatar.huge {
  width: 2rem;
  height: 2rem;

  p {
    font-size: 1.118rem;
  }
}
