@use '../base/variables' as *;

ul {
  padding: 0 0 0 1.2em;
}

ul li {
  font: $bodyCopy2;
  color: $black;
  list-style: none;
}

ul li::before {
  content: '\2022';
  color: $secondary4;
  font-weight: bold;
  font-size: 1rem;
  display: inline-block;
  width: 1.2em;
  margin: 0 0 0 -1em;
}

.product-detail-description {
  ul {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    li {
      font-size: 0.875rem;
    }
  }
}
