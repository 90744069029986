@use '../base/variables' as *;

img.item-preview {
  width: 3.5em;
  height: 3.5em;
  border-radius: 0.6em;
  object-fit: cover;

  &.read-only {
    pointer-events: none !important;
  }
}

ui-icon.delete-image {
  cursor: pointer;
  position: relative;
  margin: -0.5em -1em;
  padding: 0;
  bottom: 3.5em;
}
