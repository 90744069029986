@use '../base/variables' as *;

ui-tab {
  display: block;
}

/* -------------------
        Container
-------------------- */

div.ui-tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

div.ui-tab div.tab-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 0;
  position: relative;
  min-height: 100%;
  text-align: center;
  box-sizing: border-box;
}

/* -------------------
   Container helper
       classes
-------------------- */

div.ui-tab.compact div.tab-wrapper {
  padding: 1rem 0 0 0 !important;
}

div.ui-tab.no-bottom-padding div.tab-wrapper {
  padding-bottom: 0 !important;
}

div.ui-tab.no-top-padding div.tab-wrapper {
  padding-top: 0 !important;
}

div.ui-tab.borderless div.tab-wrapper {
  box-shadow: none;
}

div.ui-tab.left div.tab-wrapper {
  text-align: left;
}

div.ui-tab.right div.tab-wrapper {
  text-align: right;
}

/* -------------------
   Header helper
       classes
-------------------- */

div.ui-tab div.tab-header {
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $secondary2;
}

div.ui-tab span.tab-header-item {
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 0.625rem;
  margin-right: 0.25rem;
  border-bottom: 3px solid transparent;
  margin-bottom: -1px;

  h3.required {
    padding: 0 0 0 0.25rem;
    color: $text3 !important;
  }
}

div.ui-tab span.tab-header-item h3 {
  font-size: $ui3_h5;
  color: $bodyCopy1Color;
  font-weight: 400;
}

div.ui-tab span.tab-header-item.tab-selected {
  border-bottom: 3px solid $ui3_primary-gone-green;

  h3 {
    font-weight: 500;
  }
}
