@use '../base/variables' as *;

/* -------------------
        Maps
-------------------- */
div.ui-job-settings-map,
div.ui-marker-map {
  width: 100%;
  height: 100%;

  .gmnoprint,
  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google4_hdpi.png"]
  {
    display: none !important;
  }
}

div.ui-google-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  div.gm-style-mtc,
  div.gm-style-cc,
  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google4_hdpi.png"]
  {
    display: none !important;
  }
}

div.make-search-map.content {
  position: absolute;
  height: auto;
  bottom: 1.25rem;
  left: 3.5rem;
  right: 3.5rem;
}

ui-set-location {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a.ui-marker-map-navigate-button {
  font: $bodyCopy3;
  color: $white;
  background: $black;
  border-radius: 2rem;
  padding: 0.3rem 1rem;
  text-decoration: none;
}

div.ui-marker-map-navigate-wrapper {
  padding: 0.5rem 0;
}

div.ui-marker-map-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }
}

div.map-select-wrapper {
  z-index: 3;
  width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide});
  position: absolute;
  top: 7em;
  left: 0;
  padding: $pageContentPadding;
}

img.current-location-marker {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: absolute;
  bottom: 5%;
  right: 5%;
  background: $white;
  border-radius: 3rem;
}

div.ui-set-location-header {
  position: sticky;
  top: 0;
  background: $white;
  height: $applicationHeaderHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;

  &.absolute {
    position: absolute;
  }
}
