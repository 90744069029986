@use '../base/variables' as *;

div.sw-splash-screen {
  background: $primary3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  justify-content: center;
  color: $secondary3;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.2em;
}
