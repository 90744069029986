@use '../base/variables' as *;

div.ui.available-balance {
  display: flex;
  align-items: baseline;
}

/* -------------------
        Modes
-------------------- */
div.ui.available-balance > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $ui3_primary-purple;
}

div.ui.available-balance.small-light > * {
  color: $text1;
}

div.ui.available-balance.inverted > * {
  color: $white;
}

div.ui.available-balance.positive > * {
  color: $text4;
}

div.ui.available-balance.neutral > * {
  color: $secondary2 !important;
}

div.ui.available-balance.crossed > * {
  text-decoration: line-through;
}

div.ui.available-balance.neutral::before {
  color: $secondary2 !important;
}

div.ui.available-balance.inverted::before {
  color: $white;
}

div.ui.available-balance.positive::before {
  content: '+';
  color: $text4;
  position: relative;
  right: 0.1em;
}

div.ui.available-balance.negative::before {
  color: $black;
  position: relative;
  right: 0.1em;
}

div.ui.available-balance::before {
  font: $bodyCopy1;
}

div.ui.available-balance.small::before {
  font: $bodyCopy2;
}

div.ui.available-balance.small-light::before {
  font: $bodyCopy2;
}

div.ui.available-balance.h1::before {
  font: $h1;
}

div.ui.available-balance.h2::before {
  font: $h2;
}

div.ui.available-balance.h3::before {
  font: $h3;
}

div.ui.available-balance.h4::before {
  font: $h4;
}

div.ui.available-balance.leftAlign {
  place-content: flex-start;
}

div.ui.available-balance.rightAlign {
  place-content: flex-end;
}

div.ui.available-balance.centerAlign {
  place-content: center;
}

div.ui.available-balance h1.smallDecimal {
  font-size: 1em;
  align-self: flex-start;
  margin-top: 0.3rem;
}
