@use '../base/variables' as *;

div.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - #{$applicationHeaderHeight});
  margin: $applicationHeaderHeight 0 0;
  overflow: hidden;
  z-index: 100;
}

/* -------------------
    Modal - form
-------------------- */
div.modal .widget {
  width: 100%;
}

div.modal .widget h3 {
  margin-bottom: 1.25em;
}

div.modal .widget .form-field-group {
  margin-bottom: 1.75em;
}

/* -------------------
    Modal - Error
-------------------- */
div.modal.error {
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: $visibleModalLayer;
  text-align: center;
  animation: $fadeInAnimation;
}

div.modal.error div.modal-content {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  min-height: 8em;
  width: 85vw;
  background: $white;
  border-radius: 1.7em;
  align-items: center;
  word-break: break-word;
  box-sizing: border-box;
  padding: 1.5em;
  max-height: 85vh;
  overflow: hidden;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    width: calc(0.85 * #{$containerContentMaxWidth});
  }

  div.modal-title {
    font: $h2;
  }

  ui-icon[icon='cross'] {
    cursor: pointer;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    z-index: 1;
    margin: 0 0 0 auto;
  }
}

div.modal-content.size-small {
  width: 40vw !important;

  @media #{$mobile} {
    width: 70vw !important;
  }
}

div.modal.error div.modal-description {
  font: $bodyCopy1;
  color: $bodyCopy1Color;
}

/* -------------------
    Modal - Fade In
-------------------- */
div.modal.fade-in {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: $visibleModalLayer;
  text-align: center;
  animation: $fadeInAnimation;
}

div.modal.fade-in div.modal-content {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 8em;
  height: fit-content;
  width: 85%;
  max-height: 85%;
  overflow-y: auto;
  background: $white;
  border-radius: 1.7em;
  align-items: center;
  word-break: break-word;
  box-sizing: border-box;
  margin: auto;
  padding: 1.5em;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    width: calc(0.85 * #{$containerContentMaxWidth});
  }

  div.modal-title {
    font: $h2;
  }

  ui-icon[icon='cross'] {
    cursor: pointer;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    z-index: 1;
    margin: 0 0 0 auto;
  }

  div.scrollable-content {
    width: 100%;
  }
}

.modal.fade-in div.modal-description {
  font: $bodyCopy1;
  color: $text1;
}

/* -------------------
 Modal - Slide In Up
-------------------- */
div.slide-in-up-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 1em);
}

div.modal.slide-in-up {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100vh;
  background-color: $visibleModalLayer;
  animation: $fadeInAnimation;
  overflow: hidden;
  z-index: 100 !important;
}

div.modal.slide-in-up div.modal-title {
  font-size: $ui3_h3;
  line-height: $ui3_h3-line;
  font-weight: 500;
  color: $ui3_primary-purple;
  text-align: center;
  margin: 0 auto 2rem auto;
  max-width: 75%;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    font-size: $ui3_h2;
    line-height: $ui3_h2-line;
  }
}

div.modal.slide-in-up div.modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  background: $white;
  border-top-left-radius: $ui3_rounded-xl;
  border-top-right-radius: $ui3_rounded-xl;
  max-height: calc(100vh - 20px);
  box-sizing: border-box;
  text-align: center;
  margin: auto auto 0 auto;
  padding: 1rem;
  position: relative;
  animation: $fadeInAnimation;

  @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
    width: $containerContentMaxWidth;
    padding: 2rem;
    border-radius: $ui3_rounded-xl;
    margin-bottom: auto;
  }

  ui-icon[icon='cross'] {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    z-index: 1;

    @media #{$fullScreen}, #{$largeScreen}, #{$tablet} {
      right: 2rem;
      top: 2.5rem;
    }
  }

  &.no-top-padding {
    padding: 1.5rem 1rem 1.5rem;
  }
}

div.modal.slide-in-up div.scrollable-content {
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* -------------------
 Modal - Spinner
-------------------- */
div.application-loader {
  overflow: hidden;
  height: inherit;
  width: inherit;
}

div.application-loader > div {
  position: absolute;
  top: 40%;
  left: 50%;
}

div.modal.spinner {
  z-index: 30 !important;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  background-color: $pageBackground;
  text-align: center;
}

div.modal.visible-spinner {
  z-index: 30 !important;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  background-color: $visibleModalLayer;
  text-align: center;
}

div.modal.spinner div.spinner-modal-content-visible,
div.modal.spinner div.spinner-modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

div.modal.spinner div.spinner-modal-content-visible div.modal-description-wrapper,
div.modal.spinner div.spinner-modal-content div.modal-description-wrapper {
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  bottom: 45%;
  text-align: center;
}

div.modal.spinner div.spinner-modal-content-visible div.modal-description-wrapper div.modal-description,
div.modal.spinner div.spinner-modal-content div.modal-description-wrapper div.modal-description {
  font: $bodyCopy2;
  color: $text1;
}

/* -------------------
 Cookies Modal
-------------------- */
div.cookies-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #000000d1;
  padding: 0.5rem;
  z-index: 9999999999999999999999999;

  p,
  h3 {
    color: $white;

    span.link {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  span.message {
    margin: 0 0.5rem;
    text-align: left;
  }

  span.accept-btn {
    cursor: pointer;
    padding: 0.4rem;
    margin: 0 0 0 auto;
    border: 1px solid white;
    border-radius: $ui3_rounded-xl;
  }
}
