@use '../base/variables' as *;

div.ui-hamburger {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

div.ui-hamburger::after,
div.ui-hamburger::before,
div.ui-hamburger div {
  background-color: $black;
  border-radius: 3px;
  content: '';
  display: block;
  height: 2px;
  margin: 5px 0;
  transition: all 200ms ease-in-out;
}

div.ui-hamburger::after,
div.ui-hamburger::before {
  width: 1rem;
}

div.ui-hamburger.open::after,
div.ui-hamburger.open::before,
div.ui-hamburger.open div {
  width: 1.25rem;

  @media #{$tablet}, #{$largeScreen}, #{$fullScreen} {
    background-color: $black;
  }

  background-color: $black;
}

div.ui-hamburger.open::before {
  transform: translateY(5px) rotate(135deg);
}

div.ui-hamburger.open::after {
  transform: translateY(-9px) rotate(-135deg);
}

div.ui-hamburger.open div {
  transform: scale(0);
}
