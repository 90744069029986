@use '../base/variables' as *;

div.ui-customer-onboarding-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.ui-customer-onboarding-widget div.buttons-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

div.ui-promo-code-widget {
  div.apply-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    ui-icon {
      margin: 0 0.5rem 0 0;
    }
  }

  div.applied-content {
    display: flex;
    justify-content: space-between;

    ui-icon {
      margin: 0 1rem 0 0;
    }
  }
}

div.ui-mobi-money-widget {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  background: $secondary3;
  padding: 1rem 1.31rem 1rem 2.7rem;
}

div.ui-mobi-money-widget span.description {
  margin: 0 auto 0 1rem;
}

div.ui-mobi-money-widget span.description div.available {
  display: flex;
  align-items: center;
  text-align: left;

  h3 {
    margin: 0 0.25em 0 0;
  }
}

div.ui-merchant-mobi-money-wallet-inline-widget {
  width: 100%;
  height: 3.438rem;
  display: flex;
  align-items: center;
  background: $secondary1;
  padding: 0 $pageContentPaddingOnSide;
  box-sizing: border-box;
}

div.ui-customer-mobi-money-wallet-inline-widget {
  width: 100%;
  height: 3.438rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

div.ui-merchant-mobi-money-wallet-inline-widget span.text,
div.ui-customer-mobi-money-wallet-inline-widget span.text {
  flex: 1;
  display: flex;
  align-items: center;

  ui-icon {
    margin: 0 0.5em 0 0;
  }
}

div.ui-merchant-mobi-money-wallet-inline-widget span.price,
div.ui-customer-mobi-money-wallet-inline-widget span.price {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  b {
    margin: 0 0 0 0.25rem;
  }
}

div.ui-merchant-mobi-money-wallet-inline-widget span.balance,
div.ui-customer-mobi-money-wallet-inline-widget span.balance {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

div.ui-personal-loans-widget {
  border-radius: $ui3_rounded-xl;
  background: $ui3_primary-white;
  padding: 0.75rem 1rem;
  margin: 0 0 0.25rem 0;
  border: 1px solid $ui3_secondary-concrete;
  box-sizing: border-box;
  width: 100%;

  &.compact {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}

div.ui-convert-points-widget {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $ui3_rounded-xl;
  background: $ui3_primary-white;
  padding: 0.75rem 1rem;
  border: 1px solid $ui3_secondary-concrete;
  box-sizing: border-box;
}

div.ui-convert-points-widget.promo-widget {
  box-shadow: none;
  border: 2px dashed $secondary2;
}
