@use '../base/variables' as *;

div.ui-rating {
  display: flex;
  flex-direction: row;
  margin: 0.2em 0;
}

div.ui-rating.align-left {
  justify-content: flex-start;
}

div.ui-rating.align-right {
  justify-content: flex-end;
}

div.ui-rating.align-center {
  justify-content: center;
}

/* Unchecked star */
div.ui-rating .star {
  cursor: pointer;
  font-size: 1em;
  overflow: hidden;

  &:not(:last-child) {
    padding-right: 0.35em;
  }
}

/* Reating is read only */
div.ui-rating.read-only > * {
  cursor: default;
  pointer-events: none;
}

/* sizes */
div.ui-rating.mini .star {
  &:not(:last-child) {
    padding-right: 0.1em;
  }
}

div.ui-rating.tiny .star {
  &:not(:last-child) {
    padding-right: 0.15em;
  }
}

div.ui-rating.small .star {
  &:not(:last-child) {
    padding-right: 0.2em;
  }
}

div.ui-rating.large .star {
  &:not(:last-child) {
    padding-right: 0.4em;
  }
}

div.ui-rating.big .star {
  &:not(:last-child) {
    padding-right: 0.5em;
  }
}

div.ui-rating.huge .star {
  &:not(:last-child) {
    padding-right: 0.6em;
  }
}

div.ui-rating.massive .star {
  &:not(:last-child) {
    padding-right: 0.5em;
  }
}

/* -------------------
   Linear bar rating
-------------------- */
div.ui-linear-rating {
  width: 100%;
  margin: 0 0 1em;
}

div.ui-linear-rating div.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 0;
}

div.ui-linear-rating div.row p {
  width: 7.5em;
  text-align: left;
}

div.ui-linear-rating div.row span.bar {
  flex: 1;
  width: 100%;
  height: 0.35em;
  border-radius: 2em;
  background: $secondary2;
}

div.ui-linear-rating div.row span.bar div.value {
  height: 0.35em;
  border-radius: 2em;

  &.excellent {
    background: #3b9a4b;
  }

  &.very-good {
    background: #77ca27;
  }

  &.good {
    background: #ffdf00;
  }

  &.average {
    background: #ff8e01;
  }

  &.poor {
    background: #f00;
  }
}
