@use '../base/variables' as *;

$buttonHeight: 3rem;

ui-button.ui2_button {
  width: 100%;

  @media #{$tabletPlus} {
    &:not(.is-fluid) {
      max-width: $ui3_maxWidth-control;
    }
  }

  /* -------------------
  Global Button Settings
  -------------------- */
  button {
    cursor: pointer;
    font: $buttonFont;
    height: $buttonHeight;
    display: inline-flex;
    align-items: center;
    color: $buttonDefaultColor;
    text-align: center;
    border-radius: $ui3_rounded-lg;
    outline: none;
    transition: all 250ms;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  /* -------------------
          Content
  -------------------- */
  button.content > span.button-content {
    width: 100%;
    padding: 0 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* -------------------
      Default Button
  -------------------- */
  button.callToAction,
  button {
    height: $buttonHeight;
    background: $buttonDefaultBackground;
    border: $buttonDefaultBorder;
  }

  button > span.button-content {
    color: $buttonDefaultColor;
  }

  button.small {
    height: 2.5rem;
    font: normal normal 500 $ui3_base Roboto, sans-serif;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  /* -------------------
     Selected Button
  -------------------- */
  button.selected.callToAction,
  button.selected {
    background: $buttonSelectedBackground;
    border: $buttonSelectedBorder;
  }

  button.selected > span.button-content {
    color: $buttonSelectedColor;
  }

  button.selectedBlue.callToAction,
  button.selectedBlue {
    background: $ui3_secondary-ultramarine;
    border: $ui3_secondary-ultramarine;
  }

  button.selectedBlue > span.button-content {
    color: $buttonSelectedColor;
  }

  button.selectedBlack.callToAction,
  button.selectedBlack {
    background: $ui3_primary-purple;
    border: $ui3_primary-purple;
  }

  button.selectedBlack > span.button-content {
    color: $buttonSelectedColor;
  }

  /* -------------------
         disabled
  -------------------- */
  button.disabled.callToAction,
  button.disabled {
    cursor: default;
    background: $buttonDisabledBackground;
    color: $buttonDisabledColor;
    border: $buttonDisabledBorder;
  }

  &.is-on-dark-background {
    button.disabled.callToAction,
    button.disabled {
      background: $ui3_primary-white !important;

      & > span.button-content {
        color: $ui3_secondary-concrete !important;
      }
    }
  }

  /* -------------------
  call to action (CTA)
  -------------------- */
  button.callToAction {
    width: 100%;
    height: $buttonHeight;
    font: $buttonCTAFont;
    border-radius: $ui3_rounded-lg;
    margin: 0 auto;
  }

  button.callToAction.padded,
  button.callToAction.selected.padded {
    margin: 0 auto 1em !important;
  }

  button.callToAction > span.button-content {
    padding: 0 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* -------------------
      Action Button
  -------------------- */
  div.action-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
  }

  div.action-button > button {
    height: 3.3rem;
    width: 3.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary1;
    border: initial !important;
    border-radius: $ui3_rounded-xl;
    padding: 0 !important;
  }

  div.action-button > button ui-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 3.3rem;
    width: 3.3rem;
    border-radius: $ui3_rounded-xl;
    overflow: hidden;
  }

  div.action-button > button img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 3.3rem;
    width: 3.3rem;
    border-radius: $ui3_rounded-xl;
    object-fit: cover;
  }

  div.action-button > div.button-description {
    width: 5.2em;
    font: $bodyCopy2;
    color: $black;
    text-align: center;
    white-space: normal;
  }

  /* -------------------
         Borderless
  -------------------- */
  button.borderless {
    border: none;
  }

  button.inverted {
    background-color: $white;
    border-color: $white;
  }

  button.inverted > span.button-content {
    color: $black;
    padding: 0 0.25rem;
    font-weight: bold;
  }

  button.facebook {
    background-color: #4267b2;
    border-color: #6583c0;
  }

  button.facebook > span.button-content {
    color: $white;
    padding: 0 0.25rem;
    justify-content: left;
  }

  button.facebook > span.button-content ui-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  button.google {
    background-color: #db504b;
    border-color: #df6561;
  }

  button.google > span.button-content {
    color: $white;
    padding: 0 0.25rem;
    justify-content: left;
  }

  button.google > span.button-content ui-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  button.apple {
    background-color: #2c2c2e;
    border-color: #1c1c1e;
  }

  button.apple > span.button-content {
    color: $white;
    padding: 0 0.25rem;
    justify-content: left;
  }

  button.apple > span.button-content ui-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  /* -------------------
         fluid
  -------------------- */
  &[fluid='true'] {
    width: 100%;

    &[padded='true'] {
      width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide} - 2px);
    }
  }

  &[fluid='true'] button.fluid {
    width: 100%;
  }

  &[fluid='true'] button.fluid span.button-content {
    margin-left: auto;
    margin-right: auto;
  }

  button.fluid {
    width: 100%;
    margin: 0 auto 0 !important;
  }

  button.fluid.padded {
    width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide} - 2px);
  }

  button.fluid span.button-content {
    margin-left: auto;
    margin-right: auto;
  }

  /* -------------------
         align
  -------------------- */

  &[aligned='bottom'] {
    margin: auto auto 0 !important;
  }

  &[aligned='bottom'] ~ &[aligned='bottom'] {
    margin: 0 auto 1em !important;
  }

  &[aligned='top'] {
    margin: 0 auto auto !important;
  }

  &[aligned='top'] ~ &[aligned='top'] {
    margin: 1em auto 0 !important;
  }

  /* -------------------
         compact
  -------------------- */
  button.compact {
    margin: 0;
  }

  /* -------------------
         Helpers
  -------------------- */
  button > * {
    pointer-events: none;
  }

  div.badge-label {
    position: absolute;
    right: -0.75rem;
    top: -0.75rem;
    background-color: $ui3_secondary-ultramarine;
    padding: 0.25rem;
    border-radius: $ui3_rounded-lg;
    min-width: 1rem;
  }

  div.badge-label > p {
    color: $white;
  }
}
