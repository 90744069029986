ui-camera {
  flex: 1;
  display: flex;
  flex-direction: column;
}

div.webcam-wrapper {
  video {
    height: auto !important;
    width: 100% !important;
  }
}
