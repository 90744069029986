@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  --mdc-typography-headline4-letter-spacing: normal;
  --mdc-typography-headline6-letter-spacing: normal;
  --mdc-typography-subtitle1-letter-spacing: normal;
  --mdc-typography-subtitle2-letter-spacing: normal;
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-body2-letter-spacing: normal;
  --mdc-typography-caption-letter-spacing: normal;
  --mdc-typography-button-letter-spacing: normal;
  --mdc-typography-overline-letter-spacing: normal;
}

img {
  max-width: unset; // FIX for AVO 2
}

// do not show google account picker in the top right corner - library does not work properly
#credential_picker_container,
#credential_picker_iframe {
  display: none !important;
}

.list-default {
  ul,
  ol {
    list-style: revert;
  }
}

@keyframes router-load-indicator-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.router-load-indicator {
  // Delay the animation for a fast network connection (so users don't see the loader).
  animation-delay: 100ms;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-name: router-load-indicator-animation;
}
