@use '../base/variables' as *;
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.cdk-overlay-container {
  position: fixed;
  z-index: 10000 !important;
}

$inputBorder: 1px solid $secondary2;

$inputFont: $bodyCopy1;
$inputFontColor: $bodyCopy1Color;
$inputFontErrorColor: $text3;
$inputCaretColor: $text4;

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* -------------------
    time Input
-------------------- */
div.time-input {
  display: flex;
  position: relative;
  min-height: 3.125rem;
  width: calc(100% - 2px);
  border-radius: 0.625rem;
  border: $inputBorder !important;
  padding: 0 0.8rem;
  margin: 1rem auto 0 auto;
  box-sizing: border-box;
}

div.time-input.aligned-right {
  margin: 1rem 0 0 auto;
}

div.time-input.aligned-left {
  margin: 1rem auto 0 0;
}

/* -------------------
    time Title
-------------------- */
div.time-input > p {
  position: absolute;
  top: -0.8em;
  margin-left: -0.8em;
  font: $bodyCopy3;
  color: $bodyCopy3Color;
  width: fit-content;
  padding: 0 0.4rem !important;
  background: $white !important;
  opacity: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  animation: $fadeInAnimation;
}

div.time-input > p.visible {
  opacity: 1;
}

/* -------------------
    time Input
-------------------- */
div.time-input > input {
  flex: 1;
  font: $inputFont;
  color: $inputFontColor;
  border: none !important;
  outline: none !important;
  caret-color: $inputCaretColor;
  text-align: left;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.time-input > div.time-input-modal {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  background: $white;
  padding: 1rem 0 1rem 2rem;
  z-index: 9;
  right: 1rem;
  top: 1rem;
  border-radius: 0.25rem;
}

ngx-mat-timepicker > * {
  text-align: center;
}

div.time-input > input::placeholder {
  color: $text1;
}

/* sizes */
div.time-input[width='half'] {
  width: 50% !important;
}

div.time-input[width='fluid'] {
  width: 100% !important;
}

/* -------------------
       required
-------------------- */
div.time-input.required span.required-star {
  position: absolute;
  right: 1rem;
  top: 1.05rem;
  color: $text3;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}

div.time-input.error.required span.required-star {
  color: $text3;
}

/* -------------------
       error
-------------------- */
div.time-input.error {
  border-color: $inputFontErrorColor !important;

  p.small.error {
    font-size: 0.625rem !important;
  }
}

div.time-input > div.loader {
  position: absolute;
  height: 1rem;
  width: 1rem;
  right: 1rem;
  top: 1rem;
}

/* -------------------
       Label
-------------------- */
div.time-input-label {
  text-align: left;
  line-height: 1;

  i.bold {
    font-weight: bold;
  }
}

div.time-input p.visible {
  background: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  span.label-text {
    background: white;
    padding: 0 0.4em;
  }

  ui-icon.info-tooltip {
    margin: 0 0.4em;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
  }
}

i.time-input-tooltip {
  position: absolute;
  background-color: $black;
  width: 50vw;
  text-align: left;
  color: $white;
  padding: 0.6rem;
  border-radius: 0.2rem;
  z-index: 100;
  right: 0;
  top: 1em;
  cursor: pointer;
}

/* -------------------
       Disabled
-------------------- */
div.time-input.disabled {
  cursor: default !important;
  opacity: 0.6 !important;
}

div.time-input.disabled > * {
  pointer-events: none !important;
}
