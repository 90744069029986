@use '../base/variables' as *;

div.profile.gallery {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 1em;
}

div.profile.gallery img.gallery-item {
  width: 42vw;
  height: 42vw;
  border-radius: 0.7em;
  background: $secondary2;
  object-fit: cover;
  margin: 0.3em;
}

div.profile.gallery.smallThumbnails {
  justify-content: start;
  margin: 0;

  img.gallery-item {
    cursor: pointer;
    width: 3.5em;
    height: 3.5em;
  }
}

div.gallery-modal-detail-wrapper {
  text-align: left;
  width: auto;
}

div.gallery-modal-detail-wrapper img.detail-item {
  width: 100%;
  min-height: 20em;
  border-radius: 0.7em;
  background: $secondary2;
  margin: 1em 0 0;
}

div.gallery-modal-detail-wrapper .image-wrapper {
  display: block;
  width: 100%;
  min-height: 20em;
  position: relative;
}

div.gallery-modal-detail-wrapper ui-icon[icon='dustbin--x2'] {
  position: absolute;
  right: 0.625rem;
  bottom: 0.625rem;
}

div.gallery-modal-detail-wrapper div.galery-overview {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  margin: 0 0 1em;
}

div.gallery-modal-detail-wrapper div.galery-overview img.gallery-overview-item {
  cursor: pointer;
  width: 3.5em;
  height: 3.5em;
  object-fit: cover;
  border-radius: 0.7em;
  background: $secondary2;
  margin: 0 0.3em;
}

div.gallery-modal-detail-wrapper div.galery-overview img.gallery-overview-item:first-child {
  margin: 0 0.3em 0 0;
}

div.gallery-modal-detail-wrapper div.galery-overview img.gallery-overview-item:last-child {
  margin: 0 0 0 0.3em;
}

div.gallery-modal-detail-wrapper h3,
ui-icon[icon='close'] {
  color: $black;
}
