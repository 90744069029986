@use '../base/variables' as *;

div.ui.quantity.picker {
  width: 6rem;
  height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

div.ui.quantity.picker input {
  font: $h3;
  font-size: 0.6875rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  border: 0;
  outline: 0;
  padding: 0;
}

div.ui.quantity.picker input[type='number']::-webkit-inner-spin-button,
div.ui.quantity.picker input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.ui.quantity.picker input[type='number'] {
  -moz-appearance: textfield;
}

div.ui.quantity.picker span.plus,
div.ui.quantity.picker span.minus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  background: $ui3_primary-go-green;
  cursor: pointer;
  border-radius: 100%;
  flex-shrink: 0;

  a {
    font-size: 0.6875rem;
    font-weight: 700;
  }
}
