@use '../base/variables' as *;

@mixin ellipsis-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

table.ui-table {
  width: 100%;
  font: $h4;
  color: $tertiary1;
}

table.ui-table thead {
  background: $tertiary1;
  border-radius: 1.5rem;
}

table.ui-table tr {
  height: auto;
  text-align: left;
}

table.ui-table th {
  font: $bodyCopy4;
  color: $white;
  padding: 0.64rem 0;
  vertical-align: middle;
  box-sizing: border-box;

  &.checkbox {
    padding: 0;
  }

  &:first-child {
    padding-left: 1rem;
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  &:last-child {
    padding-right: 1rem;
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  div.table-header-cell {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    span.icon-wrapper {
      width: 0.75rem;
      margin-left: 0.35rem;
    }
  }
}

table.ui-table td {
  font: $bodyCopy2;
  vertical-align: middle;
  padding: 0.5rem 0;
  box-sizing: border-box;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
  }

  div.table-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2rem;

    div.table-item-dropdown-wrapper {
      max-width: 10rem;
    }
  }

  div.table-item.ellipsisOverflow {
    p {
      @include ellipsis-overflow();
    }

    b {
      @include ellipsis-overflow();
    }

    a {
      @include ellipsis-overflow();
    }

    h1 {
      @include ellipsis-overflow();
    }

    h2 {
      @include ellipsis-overflow();
    }

    h3 {
      @include ellipsis-overflow();
    }
  }
}

table.ui-table td div.table-item div.set-of-actions div.action-item {
  cursor: pointer;
  padding: 0.15rem 0.25rem;
  margin-bottom: 0.25rem;
  background: $secondary3;
  border-radius: 0.25rem;
  width: 7rem;

  &:last-child {
    margin: 0;
  }
}

table.ui-table tbody tr:nth-child(even) {
  background: #f5f4f4;

  td {
    &:first-child {
      border-top-left-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }

    &:last-child {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
  }
}

table.ui-table tbody tr:nth-child(odd) {
  background: $white;
}

table.ui-table span.table-thumbnail img {
  padding: 0.275rem;
  border-radius: 0.5rem;
  border: 1px solid $secondary2;
  object-fit: cover;
}

div.pagination {
  margin: 1.5rem 0;
  position: relative;

  div.menu {
    position: absolute;
    margin: 0 10.5rem 6.5rem 0;
    padding: 0.3rem 0.75rem;
    background: $white;
    border-radius: 0.5rem;
    z-index: 2;
    box-shadow: $ui3_shadow-item;

    div.item {
      font: $bodyCopy2;
      color: $black;
      cursor: pointer;
    }

    div.item.active,
    div.item:hover {
      font: $bodyCopy4;
    }
  }
}

pagination-controls.table-pagination {
  .ngx-pagination {
    width: fit-content;
    margin: auto 0;
    padding: 0 0 0 0.45rem;
  }

  .ngx-pagination::before,
  .ngx-pagination::after {
    content: ' ';
    display: table;
  }

  .ngx-pagination::after {
    clear: both;
  }

  .ngx-pagination li {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-right: 0.0625rem;
    border-radius: 0;
    display: inline-block;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    color: $black;
    font: $bodyCopy2;
    display: block;
    padding: 0.1875rem 0.45rem;
    border-radius: 0;
    cursor: pointer;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: transparent;
    font: $bodyCopy4;
  }

  .ngx-pagination .current {
    padding: 0.1875rem 0.45rem;
    background: transparent;
    font: $bodyCopy2;
    color: $text2;
    cursor: default;
  }

  .ngx-pagination .disabled {
    padding: 0.1875rem 0.45rem;
    color: $secondary3;
    cursor: default;
  }

  .ngx-pagination .disabled:hover {
    background: transparent;
  }

  .ngx-pagination li::before,
  .ngx-pagination .small-screen,
  .ngx-pagination .pagination-previous,
  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before,
  .ngx-pagination .pagination-next,
  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    display: none;
  }

  .ngx-pagination .show-for-sr {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }

  @media screen and (max-width: 601px) {
    .ngx-pagination.responsive .small-screen {
      display: inline-block;
    }

    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
      display: none;
    }
  }
}

span.number-of-pages {
  cursor: pointer;
  width: 7rem;
  padding: 0.25rem 0.65rem;
  background: $secondary3;
  border-radius: 0.25rem;
  box-sizing: border-box;

  span.text {
    font: $bodyCopy2;
    color: $black;
  }
}

tr.table-detail-row {
  height: 0;

  td.cdk-column-expandedDetail {
    padding: 0;
  }
}

div.table-element-detail {
  overflow: hidden;
  display: flex;
}

tr.table-expandable-row {
  cursor: pointer;
}

div.row.content-stock {
  margin: 0.5rem 0;
}
