@use '../base/variables' as *;

div.ui-skeleton-screen-wrapper {
  overflow: hidden;
}

div.ui-skeleton-screen {
  cursor: initial;
  pointer-events: none;
  background: linear-gradient(to right, #eee 8%, darken(#eee, 5) 18%, #eee 33%);
  background-size: 200% 50px;
  animation: preloadAnimation 2s infinite;
  position: relative;
}

@keyframes preloadAnimation {
  from {
    background-position: -280px 0;
  }

  to {
    background-position: 300px 0;
  }
}

@keyframes preloadAnimation2 {
  0% {
    background-color: $secondary3;
  }

  50% {
    background-color: $secondary2;
  }

  100% {
    background-color: $secondary3;
  }
}

div.ui-skeleton-screen div.rect {
  height: 4rem;
  width: 4rem;
  border: 8px solid $pageBackground;
  // Clear fix
  border-right: 100vw solid $pageBackground;
  box-sizing: border-box;
}

div.ui-skeleton-screen div.line {
  height: 3rem;
  width: 100%;
  border: 8px solid $pageBackground;
  box-sizing: border-box;
}

div.ui-skeleton-screen div.card {
  height: 9rem;
  width: 100%;
  border: 8px solid $pageBackground;
  box-sizing: border-box;
}

div.ui-skeleton-screen div.list {
  height: 1rem;
  width: 100%;
  border-top: 4px solid $pageBackground;
  border-bottom: 4px solid $pageBackground;
  border-right: 8px solid $pageBackground;
  border-left: 8px solid $pageBackground;
  box-sizing: border-box;
}

div.rects-row {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // Clear fix
  border-right: 100vw solid $pageBackground;

  span.rect {
    width: 25%;
    border: 8px solid $pageBackground;
    box-sizing: border-box;
  }
}

div.ui-skeleton-screen.compact * {
  div.card,
  div.line {
    width: 100%;
  }

  border: none !important;
}

div.ui-skeleton-screen-dashboard-wrapper {
  background: $white;
}

div.ui-skeleton-screen-dashboard-wrapper div.background {
  background-color: $secondary1;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

span.rounded {
  border-radius: 0.75rem;
}

span.label-small {
  width: 3.75rem;
  height: 1rem;
  box-sizing: border-box;
  display: flex;
  background-color: $secondary3;
}

span.label-medium {
  width: 8rem;
  height: 1rem;
  box-sizing: border-box;
  display: flex;
  background-color: $secondary3;
}

span.label-full {
  width: 100%;
  height: 1rem;
  box-sizing: border-box;
  display: flex;
  background-color: $secondary3;
}

span.rect-small {
  width: 3.75rem;
  height: 3.75rem;
  box-sizing: border-box;
  background-color: $secondary3;
}

span.rect-medium {
  width: 10rem;
  height: 14rem;
  box-sizing: border-box;
  background-color: $secondary3;
}

span.rect-large {
  width: 15rem;
  height: 10rem;
  box-sizing: border-box;
  background-color: $secondary3;
}

span.rect-full {
  width: 100%;
  height: 16rem;
  box-sizing: border-box;
  background-color: $secondary3;
}

span.half-circle {
  width: 3rem;
  height: 1.5rem;
  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;
  box-sizing: border-box;
  background-color: $secondary3;
}

.loaderV2 {
  cursor: initial;
  pointer-events: none;
  animation: preloadAnimation2 2s infinite;
}
