@use '../base/variables' as *;

div.ui-carousel {
  display: block;
  overflow: hidden;
  width: calc(100vw - (2 * 1.25em));
  margin: 0 auto;
  position: relative;

  &.full-width {
    width: 100%;
  }

  @media #{$fullScreen} {
    width: 100%;
  }

  @media #{$largeScreen} {
    width: 100%;
  }

  @media #{$tablet} {
    width: 100%;
  }
}

div.ui-carousel .outer-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: 18rem;
  overflow: hidden;
}

div.ui-carousel:not(.restrict-height) .outer-wrapper {
  display: flex;
  flex-direction: column;
}

div.ui-carousel.restrict-height {
  height: 100%;

  .outer-wrapper {
    width: 100%;
    height: calc(100% - 1rem); //full height minus the action buttons
  }
}

div.ui-carousel div.action-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;

  button.carousel.button {
    height: 0.625em;
    width: 0.625em;
    display: flex;
    flex-direction: row;
    background: $carouselButtonBackground;
    border-radius: 100%;
    margin: 0 0.15rem 0.5rem;
    padding: 0;
    opacity: 0.4;
    border: none;
  }

  button.carousel.button.active {
    opacity: 1;
    width: 1.563rem;
    border-radius: 2.2em;
  }

  button.carousel.button.small {
    opacity: 0.15;
  }
}

div.ui-carousel div.wrapper,
div.ui-carousel div.wrapper span.content {
  display: flex;
  flex: 1;
  transition: margin 500ms ease-out;
  width: 100%;

  &:empty {
    display: none;
  }
}

div.ui-carousel div.wrapper .content.ngContent > *:last-child > * {
  margin-right: 0;
}

div.ui-carousel div.wrapper span.item {
  position: relative;
  width: 100%;
  min-width: 100%;
  transition: margin 500ms ease-out;

  &:not(:last-child) {
    margin-right: 1em;
  }

  ui-icon {
    width: 80%;
    display: inline-block;
  }

  img,
  i.slide.icon,
  ui-icon.slide.icon {
    width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide});
    height: auto;
    min-width: 100%;
    object-fit: contain;
  }

  img {
    object-fit: contain;
    object-position: 50% 50%;
    background-repeat: no-repeat;
    height: 100%;
  }

  ui-icon.slide.cover img {
    object-fit: cover;
  }

  ui-icon[icon='favourite'],
  ui-icon[icon='favourite-active'],
  ui-icon[icon='share'] {
    width: 1.56rem;
  }

  h2,
  p {
    word-break: break-word;
  }
}

div.ui-carousel.restrict-height .outer-wrapper div.wrapper span.item {
  i,
  img,
  ui-icon {
    min-width: 0;
    max-width: 55vh;
  }
}

/* Maximum aspect ratio */
@media (orientation: landscape) {
  div.ui-carousel.restrict-height .outer-wrapper div.wrapper span.item {
    i,
    img,
    ui-icon {
      max-width: 45vh;
    }
  }
}

div.ui-carousel div.wrapper span.item div.icons {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  width: 1.563em;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.2rem;
  z-index: 1;

  ui-icon:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
