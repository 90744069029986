@use '../base/variables' as *;

div.ui.radio {
  display: flex;
  height: 1.25rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:not(.standalone) {
    margin: 0 0 0.5em 0;
  }
}

div.ui.radio span.wrapper {
  height: 1.25rem;
  width: 1.75rem;
  display: flex;
  align-items: center;
}

div.ui.radio span.wrapper.checked {
  opacity: 1;
}

/* -------------------
    label settings
-------------------- */
label.ui.radio-label {
  font: $bodyCopy1;
  color: $bodyCopy1Color;
}
