@use '../base/variables' as *;

div.ui-progress-bar {
  width: 100%;
}

div.ui-progress-bar div.wrapper {
  width: 100%;
  height: 1rem;
  border: 1px solid $secondary2;
  border-radius: 0.313rem;
  position: relative;
}

div.ui-progress-bar div.progress {
  top: 0;
  height: 1rem;
  border-radius: 0.313rem;
  position: absolute;
  background: $secondary4;
}

div.progress-animated {
  animation: fill 120s linear 1;
  animation-iteration-count: infinite;
}

@keyframes fill {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
