$ui3_primary-good-green: #0ca866;
$ui3_primary-go-green: #02e578;
$ui3_primary-gone-green: #00ff6a;
$ui3_primary-mint-green: #c5f3dd;
$ui3_primary-purple: #18011e;
$ui3_primary-violet: #2d0138;
$ui3_primary-ultra-violet: #8083f3;
$ui3_primary-white: #ffffff;

$ui3_secondary-fuchsia: #c55de8;
$ui3_secondary-magenta: #d17ded;
$ui3_secondary-lavender: #e8bef6;
$ui3_secondary-concrete: #dddae8;
$ui3_secondary-ultramarine: #1555f1;
$ui3_secondary-cyan: #5b9aee;
$ui3_secondary-crystal: #91b9ee;
$ui3_secondary-olive: #b9b90a;
$ui3_secondary-lime: #c8de23;
$ui3_secondary-toxic: #d6ff33;
$ui3_secondary-lemon: #d0ff97;
$ui3_secondary-salmon: #f8803d;
$ui3_secondary-peach: #ffbba5;

$ui3_background-grey: #f7f7f7;

$ui3_system-cobalt: #1e6fd5;
$ui3_system-cobalt-light: #d7e3f2;
$ui3_system-scarlet: #f34500;
$ui3_system-scarlet-light: #f7e1d4;
$ui3_system-ruby: #d41d06;
$ui3_system-ruby-light: #f2d6d3;

$ui3_h1: 2.25rem;
$ui3_h2: 1.5rem;
$ui3_h3: 1.25rem;
$ui3_h4: 1rem;
$ui3_h5: 0.75rem;
$ui3_h6: 0.6875rem;
$ui3_base: 0.75rem;

$ui3_h1-line: 2.5rem;
$ui3_h2-line: 1.75rem;
$ui3_h3-line: 1.5rem;
$ui3_h4-line: 1.25rem;
$ui3_h5-line: 1rem;
$ui3_h6-line: 0.875rem;
$ui3_base-line: 1rem;

$ui3_shadow-layout: 0 2px 4px 0 rgba($ui3_primary-ultra-violet, 0.2);
$ui3_shadow-item: 0 2px 4px 0 rgba($ui3_primary-violet, 0.2);

$ui3_rounded-lg: 0.5rem;
$ui3_rounded-xl: 1rem;
$ui3_rounded-full: 100%;

$ui3_maxWidth-control: 27.5rem;

/* -------------------
 General Theme colors
-------------------- */
$primary1: $ui3_primary-purple;
$primary2: $ui3_primary-purple;
$primary3: $ui3_primary-white;
$primary4: $ui3_primary-white;
$primary5: #fafad9;

$secondary1: $ui3_primary-white;
$secondary2: $ui3_secondary-concrete;
$secondary3: $ui3_secondary-concrete;
$secondary4: $ui3_secondary-magenta;
$secondary5: $ui3_primary-good-green;
$secondary6: #fcffdd;

$tertiary1: #000001;

$text1: #666;
$text2: $ui3_secondary-ultramarine;
$text3: $ui3_system-ruby;
$text4: $ui3_primary-good-green;
$text5: none;
$text6: $ui3_secondary-salmon;

$black: #000;
$white: #fff;

$visibleModalLayer: rgba(0, 0, 0, 0.32);

/* -------------------
Global Fonts Settings
-------------------- */
$fontSize: 16px; /* The size of page text */
$textColor: $ui3_primary-purple;

/* -------------------
 Global Page Settings
-------------------- */
$pageBackground: $ui3_background-grey;
$pageContentPaddingOnSide: 1rem;
$pageContentPadding: 0 $pageContentPaddingOnSide 0 $pageContentPaddingOnSide;
$pageFont: Roboto, sans-serif;

/* -------------------
      Typography
-------------------- */
$h1: normal normal 500 $ui3_h1 Roboto;
$h2: normal normal 500 $ui3_h2 Roboto;
$h3: normal normal 500 $ui3_h3 Roboto;
$h4: normal normal 500 $ui3_h4 Roboto;
$hyperlink: normal normal normal 0.875rem Roboto;
$smallerHyperlink: normal normal normal 0.75rem Roboto;
$h1Color: $ui3_primary-purple;
$h2Color: $ui3_primary-purple;
$h3Color: $ui3_primary-purple;
$h4Color: $ui3_primary-purple;
$hyperlinkColor: $ui3_primary-purple;
$smallerHyperlinkColor: $ui3_primary-purple;

$bodyCopy1: normal normal normal $ui3_base Roboto;
$bodyCopy2: normal normal normal $ui3_base Roboto;
$bodyCopy3: normal normal normal $ui3_base Roboto;
$bodyCopy4: normal normal bold $ui3_h4 Roboto;
$bodyCopy5: normal normal bold $ui3_base Roboto;
$bodyCopy6: normal normal bold $ui3_h4 Roboto;
$subtext: normal italic normal $ui3_h6 Roboto;
$bodyCopy1Color: $ui3_primary-purple;
$bodyCopy2Color: $ui3_primary-purple;
$bodyCopy3Color: $ui3_primary-purple;
$bodyCopy4Color: $ui3_primary-purple;
$bodyCopy5Color: $ui3_primary-good-green;
$bodyCopy6Color: $ui3_primary-violet;
$subtextColor: $ui3_primary-purple;

$textsMargin: 0;
$headersMargin: 0;

/* -------------------
Application Containers
-------------------- */
$applicationHeaderHeight: 3.5rem;
$applicationNavigationHeight: 3.375rem;
$projectDetailsOverviewHeight: 4em;

/* -------------------
      Container
-------------------- */
$containerMaxWidth: 100vw;
$containerBorderRadius: 0;
$containerDefaultBackgroundColor: transparent;
$containerPadding: 0;
$containerHorizontalPadding: 1rem;
$containerVerticalPadding: 1rem;
$containerContentPadding: $containerVerticalPadding $containerHorizontalPadding;
$containerContentMaxWidth: 48.938rem;

/* -------------------
   Responsibility
-------------------- */
$mobile: 'only screen and (max-width: 760px)';
$tablet: 'only screen and (min-width: 760px) and (max-width: 1024px)';
$largeScreen: 'only screen and (min-width: 1025px) and (max-width: 1366px)';
$fullScreen: 'only screen and (min-width: 1367px)';

$tabletPlus: 'only screen and (min-width: 760px)';

/* -------------------
    animation keys
-------------------- */
$fadeInDownAnimationKey: fade-in-down;
$slideInUpAnimationKey: slide-in-up;
$fadeInAnimationKey: fade-in;
$pushDownAnimationKey: push-down;
$slideUpAnimationKey: slide-up;

/* -------------------
       animations
-------------------- */
$slideUpAnimation: $slideUpAnimationKey 0.2s ease-in !important;
$pushDownAnimation: $pushDownAnimationKey 0.3s ease-in !important;
$fadeInAnimation: $fadeInAnimationKey 0.3s ease-out !important;
$slideInUpAnimation: $slideInUpAnimationKey 0.3s ease-out !important;

/* -------------------
      Others
-------------------- */
$carouselButtonBackground: #c9d82e;
$dividerBorderColor: $secondary2;
$discountFlagWidth: 1.875rem;
$buttonFont: normal normal 500 $ui3_h4 Roboto;

$buttonDefaultBorder: 1px solid $ui3_primary-purple;
$buttonDefaultBackground: $ui3_primary-white;
$buttonDefaultColor: $ui3_primary-purple;

$buttonSelectedBorder: 1px solid $ui3_primary-purple;
$buttonSelectedBackground: $ui3_primary-purple;
$buttonSelectedColor: $ui3_primary-white;

$buttonDisabledBackground: $ui3_secondary-concrete;
$buttonDisabledColor: $ui3_primary-white;
$buttonDisabledBorder: 1px solid $ui3_secondary-concrete;

$buttonCTAFont: $buttonFont;
$buttonCTABackground: $ui3_primary-purple;
