@use '../base/variables' as *;

/* -------------------
  Push notifications
-------------------- */

div.ui-push-notification {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  min-width: 16rem;
  max-width: 27.1rem;
  min-height: 1.563rem;
  border-radius: 0.75rem;
  margin: 0 auto;
  padding: 0.313rem 0;
  font: $bodyCopy2;
  color: $white;
  text-align: center;
  z-index: 1999999999999;
  background: $secondary5;
  box-shadow: $ui3_shadow-layout;
  box-sizing: border-box;
}
